.works {
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}

.works>div:first-of-type {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.works>div:first-of-type span {
  font-size: 32px;
  color: var(--main-color);
  margin-left: 10px;
}