.love {
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  border: solid 1px var(--main-color);
  background: linear-gradient(90deg, rgba(20, 25, 55, 0.06) 0%, rgba(243, 243, 243, 0) 100%);
  padding: 20px;
}

.love span {
  font-size: 32px;
}

.love>div:first-of-type {
  display: flex;
  flex-direction: row;
}

.love>div:first-of-type svg {
  margin-left: 12px;
}

.love>div:nth-of-type(2) {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  margin: 25px auto 5px auto;
  width: 80%;
}

.love>div:nth-of-type(2)>div {
  display: flex;
  flex-direction: row;
  border-radius: 42px;
  border: solid 1px var(--main-color);
  min-width: 185px;
  padding: 8px 13px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.love>div:nth-of-type(2)>div svg {
  margin-right: 20px;
  width: 35px;
  height: 35px;
  fill: var(--main-color);
}

.love>div:nth-of-type(2)>div:nth-of-type(1),
.love>div:nth-of-type(2)>div:nth-of-type(2) {
  border: solid 1px #800020;
  color: #800020;
}
