.feed {
  display: flex;
  justify-content: center;
  padding: 50px;
  box-sizing: border-box;
  margin: 0 auto;
}

.my-masonry-grid {
  display: flex;
  margin-left: -30px;
  width: 90%;
  margin: 45px auto;
}

.my-masonry-grid_column {
  padding-left: 30px;
  background-clip: padding-box;
}

.my-masonry-grid_column > div {
  margin-bottom: 30px;
}

.cardsFeed{
  position: relative;
  width: 475px;
  height: auto;
  border: solid 1px var(--main-color);
  cursor: pointer;
}

.cardsFeed > div{
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 55px;
  border-bottom: solid 1px var(--main-color);
  background-color: var(--background-color);
}

.cardsFeed > div > span:first-of-type{
  font-size: 24px;
  margin-left: 22px;
  width: 250px;
}

.cardsFeed > div > span:last-of-type{
  font-size: 18px;
  margin-right: 32px;
}

.cardsFeed > div > div{
  display: flex;
  flex-direction: row;
  width: 50px;
  justify-content: space-between;
}

.cardsFeed > div > div span{
  width: 12px;
  height: 12px;
  border: solid 1px var(--main-color);
  border-radius: 100%;
}

.cardsFeed > div > div span:first-of-type{
  background: #FFBBBF;
}

.cardsFeed > div > div span:nth-of-type(2){
  background: #FFE665;
}

.cardsFeed > div > div span:last-of-type{
  background: #ACDF99;
}

.cardsFeed img{
  width: 100%;
  height: auto;
  user-select: none;
  pointer-events: none;
}

.cardsFeed video{
  width: 100%;
  height: auto;
}