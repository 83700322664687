.ProfileCard {
  width: 100%;
  height: 75px;
  background: linear-gradient(90deg, rgba(20, 25, 55, 0.09) 0%, rgba(243, 243, 243, 0) 100%);
  border: 1px solid var(--main-color);
  cursor: default;
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px;
  position: relative;
  transition: background 0.3s ease-in-out;
}

.ProfileCard:hover {
  background: linear-gradient(90deg, rgba(20, 25, 55, 0.09) 0%, rgba(20, 25, 55, 0.09) 100%);
}

.ProfileCard>div {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}

.ProfileCard>div:first-of-type {
  font-size: 32px;
}

.ProfileCard>div:first-of-type h3 {
  font-weight: normal;
  margin-left: 45px;
  transition: transform 0.3s ease;
}

.ProfileCard>div:last-of-type span {
  font-size: 22px;
  margin-left: 16px;
}

.ProfileCard:hover h3 {
  transform: translate3d(14px, 0, 0) skew(-10deg, 0deg);
}