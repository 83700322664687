nav {
  width: 100%;
  height: 35px;
  margin-top: 25px;
}

nav>div {
  width: 460px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 150px;
}

nav>div a {
  font-size: 22px;
  cursor: pointer;
  position: relative;
}
